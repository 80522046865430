import React, { Component } from "react";

// This is our base error boundary class component - required by React
class ErrorBoundaryBase extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });

    // Log error in development
    if (process.env.NODE_ENV === "development") {
      console.group("Error Boundary Caught Error:");
      console.error("Error:", error);
      console.error("Component Stack:", errorInfo.componentStack);
      console.groupEnd();
    }

    // Call onError callback if provided
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        this.props.fallback?.(this.state.error, this.state.errorInfo) || (
          <div className="p-6 bg-red-50 border border-red-200 rounded-lg">
            <h2 className="text-xl font-semibold text-red-700 mb-4">
              Something went wrong
            </h2>
            <details className="text-sm text-red-600">
              <summary className="cursor-pointer mb-2">
                Show error details
              </summary>
              <pre className="whitespace-pre-wrap mt-2 p-4 bg-white rounded border border-red-100">
                {this.state.error?.toString()}
                {"\n\n"}
                {this.state.errorInfo?.componentStack}
              </pre>
            </details>
          </div>
        )
      );
    }

    return this.props.children;
  }
}

// Our modern functional wrapper that provides a nicer API
const ErrorBoundary = ({ children, fallback, onError }) => {
  return (
    <ErrorBoundaryBase fallback={fallback} onError={onError}>
      {children}
    </ErrorBoundaryBase>
  );
};

export default ErrorBoundary;

