import React from "react";

const LayoutPreview = ({ layout, size = "small", onClick }) => {
  // Calculate dimensions based on size prop
  const dimensions = {
    small: {
      width: 150,
      height: 100,
      padding: 2,
    },
    large: {
      width: 450,
      height: 300,
      padding: 4,
    },
  };

  const { width, height, padding } = dimensions[size];

  // Define an array of grays to alternate between zones
  const zoneColors = [
    "#e0e0e0", // Light gray
    "#d0d0d0", // Medium gray
    "#c0c0c0", // Darker gray
    "#b0b0b0", // Even darker gray
    "#a0a0a0", // Darkest gray
  ];

  const previewStyles = {
    container: {
      width,
      height,
      border: "1px solid #ccc",
      borderRadius: "4px",
      cursor: "pointer",
      overflow: "hidden", // Prevent zones from overflowing container
    },
    layoutContainer: {
      position: "relative",
      width: "100%",
      height: "100%",
      backgroundColor: "#f8f8f8", // Lighter background to contrast with zones
    },
    zone: {
      position: "absolute",
      borderRadius: "2px",
      boxShadow: "0 0 0 1px rgba(0,0,0,0.1)", // Subtle border effect
      transition: "background-color 0.2s ease", // Smooth color transition for hover
    },
  };

  return (
    <div style={previewStyles.container} onClick={onClick}>
      <div style={previewStyles.layoutContainer}>
        {layout.zones?.map((zone, index) => {
          // Create a style object for each zone
          const zoneStyle = {
            ...previewStyles.zone,
            backgroundColor: zoneColors[index % zoneColors.length],
            width: `calc(${zone.width})`,
            height: `calc(${zone.height})`,
            top: zone.top,
            left: zone.left,
            right: zone.right,
            bottom: zone.bottom,
            padding: `${padding}px`,
          };

          // Add a small label to identify the zone in the preview
          const labelStyle = {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: size === "small" ? "10px" : "14px",
            color: "rgba(0,0,0,0.5)",
            fontWeight: "bold",
          };

          return (
            <div key={`${zone.id}-${index}`} style={zoneStyle}>
              <div style={labelStyle}>{zone.id.toUpperCase()}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LayoutPreview;
