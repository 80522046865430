import React from "react";
import Branding from "../Branding";
import RGL, { WidthProvider } from "react-grid-layout";
import { ReactGridLayout as RGLEditor } from "../../pages/editor/Editor";
import { getPlaceholderArea } from "./ScreenActions";
import ScreenContext from "./ScreenContext";
import AreaComponent from "./AreaComponent";
import "./Screen.css";

import DynamicLayout from "./DynamicLayout";

const findOrCreateArea = (areas, id) => {
  let area = areas.find((area) => area.id === id);
  if (!area) area = getPlaceholderArea(id); //TODO: ask what the advantage of separating this is

  return area;
};

const Screen = (props) => {
  const { onAreaChange, onBrandingChange, uploadImage, editMode } = props;
  const ReactGridLayout = editMode ? RGLEditor : WidthProvider(RGL);

  // Calculate the available height for the layout
  const getLayoutHeight = (brandingSetting) => {
    // Account for branding area (125px) and padding (140px)
    return brandingSetting
      ? window.innerHeight - 265 // With branding
      : window.innerHeight - 140; // Without branding
  };
  // Helper function to render the appropriate layout component
  const renderLayout = (
    layout,
    areas,
    containerHeight,
    theme,
    editMode,
    props
  ) => {
    console.log("renderLayout", layout, areas, containerHeight, theme, editMode);
    console.log("props", props);

    // Check if this is a dynamic layout
    if (layout.id === "dynamic") {
      // Get the layout configuration from the screen's config
      const dynamicLayoutConfig = props.config?.layout;

      if (!dynamicLayoutConfig) {
        console.warn("Dynamic layout selected but no configuration found");
        return null;
      }

      return (
        <DynamicLayout
          layout={dynamicLayoutConfig}
          areas={areas}
          containerHeight={containerHeight}
          findOrCreateArea={findOrCreateArea}
          AreaComponent={AreaComponent}
          theme={theme}
          editMode={editMode}
          {...props}
        />
      );
    }

    // Default to grid layout
    return (
      <ReactGridLayout
        className="layout"
        isDraggable={false}
        autoSize={true}
        cols={3}
        layout={layout.value}
        margin={[20, 20]}
        rowHeight={containerHeight / 6}
      >
        {layout.value.map((v) => (
          <div key={v.i} style={{ border: "none" }}>
            <AreaComponent
              area={findOrCreateArea(areas, v.i)}
              theme={theme}
              editMode={editMode}
              {...props}
            />
          </div>
        ))}
      </ReactGridLayout>
    );
  };

  return (
    <ScreenContext.Consumer>
      {({
        screenId,
        layout,
        areas,
        theme,
        editMode,
        background,
        backgroundColor,
        socialMediaColor,
        brandingSetting,
        storeId,
        fontOverrides,
        taxData,
        scrollOverride,
        config,
      }) => {
        const containerHeight = getLayoutHeight(brandingSetting);

        return (
          <div
            style={{
              backgroundColor: `${backgroundColor}`,
              backgroundImage: `url(${background})`,
              backgroundSize: "cover",
              height: window.innerHeight,
            }}
          >
            <Branding
              uploadImage={uploadImage}
              socialMediaColor={socialMediaColor}
              onBrandingChange={onBrandingChange}
            />

            {renderLayout(layout, areas, containerHeight, theme, editMode, {
              storeId,
              screenId,
              onAreaChange,
              fontOverrides,
              taxData,
              scrollOverride,
              config,
            })}
          </div>
        );
      }}
    </ScreenContext.Consumer>
  );
};

export default Screen;
