import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

import "./Menu.css";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Menu.css";
import ScreenContext from "../Screen/ScreenContext";
import { convertColumnHeaderIncrements, parseableColumns } from '../../tmpl/menus/columns';

import dominanceHelper from "../../helpers/helper_dominance";
import inventoryHelper from "../../helpers/helper_inventory";

const prices = [
  "Price",
  "PriceHalfGram",
  "PriceTwoGram",
  "PriceGram",
  "PriceHalfEighth",
  "PriceEighth",
  "PriceHalfOunce",
  "PriceOunce",
  "PriceQuarter",
];

const pricesToMed = {
  Price: "PriceMed",
  PriceGram: "PriceGramMed",
  PriceEighth: "PriceEighthMed",
  PriceQuarter: "PriceQuarterMed",
  PriceHalfOunce: "PriceHalfOunceMed",
  PriceOunce: "PriceOunceMed",
}

const Menu = (props) => {
  let {
    columns,
    initialData,
    theme,
    title,
    settings,
    id,
    fontOverrides = {},
    overflow,
    overflowRows,
    taxData,
    scrollOverride,
    thcDecimal = "Default",
  } = props;

  // console.log('Settings', settings);
  // console.log('Initial Menu Data', initialData);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  settings.isScrolling = settings.autoScroll && (overflow === undefined || overflow === 0);

  let qsScrollSpeed = urlParams.get("scrollSpeed");
  let qsPageSpeed = urlParams.get("pagingSpeed")
    ? urlParams.get("pagingSpeed")
    : 35000;
  let qsHideHeaders = urlParams.get("hideHeaders") ? true : false;
  
  let qsColorBy = urlParams.get("colorBy"); // property to color by
  let qsColors = urlParams.get("colors");  // format [term]|[color],[term]|[color]

  let data = inventoryHelper.qsFilter(initialData, { urlParams, settings });
  
  const { config } = useContext(ScreenContext);

  // apply settings for text transform for data and headers
  // the query-string param will override the config setting
  // console.log(settings);
  let headerTransform =
    urlParams.get("headerTransform") || settings.headerTransform || null;

  let dataTransform =
    urlParams.get("dataTransform") || settings.dataTransform || null;

  const tableConfig = {
    hideHeaders: qsHideHeaders,
    colorBy: qsColorBy,
    colors: qsColors,
    headerAlignment: config.headerAlignment,
    headerTransform,
    dataTransform,
    columnConfig: settings.columnConfig || [],
  };

  React.useEffect(() => {
    if (settings.isScrolling) {
      //! NewScroll
      const container = document.getElementById(`table-container-${id}`);
      container.style.overflow = "hidden";

      setTimeout(() => {
        const waitTimeTop = 2;
        const waitTimeBot = 2;
        const pixelPerSecond = qsScrollSpeed
          ? qsScrollSpeed
          : scrollOverride && scrollOverride !== "Default"
          ? scrollOverride
          : 25;
        const scrollTime =
          (container.scrollHeight - container.offsetHeight) / pixelPerSecond;
        const totalAnimationTime = waitTimeTop + scrollTime + waitTimeBot;
        const waitTopStop = (waitTimeTop * 100) / totalAnimationTime;
        // const waitBotStart = 100 - (waitTimeBot * 100) / totalAnimationTime;
        var style = document.createElement(`style`);
        var keyframes = `\
        @keyframes scroll-${id} {\
          0%{\
            transform: translateY(0px);\
          }\
          ${waitTopStop}%{\
            transform: translateY(0px);\
          }\
          95%{\
            transform: translateY(${
              container.offsetHeight - container.scrollHeight
            }px);\
          }\
          100%{\
            transform: translateY(${
              container.offsetHeight - container.scrollHeight
            }px);\
          }\
        }\

        .tbody-scroll-${id} {\
          transform: translate3d(0, 0, 0);
          animation: scroll-${id} ${totalAnimationTime}s linear infinite;
        }\
        `;

        // console.log(style);

        if (container.offsetHeight < container.scrollHeight) {
          style.innerHTML = keyframes;
          document.getElementsByTagName("head")[0].appendChild(style);

          const tbody = document.querySelector(`.tbody-scroll-${id}`);

          const handleAnimationEnd = () => {
            tbody.style.animation = "none";
            void tbody.offsetHeight; // Trigger reflow
            tbody.style.animation = "";
          };

          tbody.addEventListener("animationend", handleAnimationEnd, false);

          return () => {
            tbody.removeEventListener("animationend", handleAnimationEnd);
          };
        } else {
          style.innerHTML = `
            .tbody-scroll-${id} {\
              transform: translate3d(0, 0, 0);
              animation: none;
            }\
          `;
          document.getElementsByTagName("head")[0].appendChild(style);
        }
      }, 0);
    }
  }, [settings, columns, data, scrollOverride, overflow, id]);

  //font override styles
  let tableStyle = theme.menu.table.style;
  if (fontOverrides && fontOverrides.dataSize !== "Default") {
    tableStyle = {
      ...theme.menu.table.style,
      gridTemplateColumns: columns.map(col => col.field).includes("Image") ? `auto minmax(${12 * fontOverrides.dataSize}px, ${
        30 * fontOverrides.dataSize
      }px) auto` : `minmax(${12 * fontOverrides.dataSize}px, ${
        30 * fontOverrides.dataSize
      }px) auto`,
    };
  }
  
  //console.log(theme.menu.header);
  let titleStyle = {...theme.menu.header.style};

  if (fontOverrides?.titleFont && fontOverrides.titleFont !== "Default") {
    titleStyle = {
      ...theme.menu.header.style,
      fontFamily: fontOverrides.titleFont,
    };
    // titleStyle.fontFamily = fontOverrides.titleFont;
  }
  if(fontOverrides?.titleColor){
    titleStyle.color = fontOverrides.titleColor;
  }
  if (
    fontOverrides &&
    fontOverrides.titleSize &&
    fontOverrides.titleSize !== "Default"
  ) {
    titleStyle.fontSize = fontOverrides?.titleSize;
  }
  // Creates table displays with the given inventory and adds padding for carousel feature
  const tableDisplay = (inventory, carousel, tableConfig) => {
    const { hideHeaders, colorBy, colors, headerAlignment, headerTransform, dataTransform, columnConfig } = tableConfig;

    return (
      <TableContainer
        id={`table-container-${id}`}
        style={{
          ...theme.container,
          width: "auto",
          transform: "translate3d(0, 0, 0)",
          willChange: "transform",
          padding: carousel ? "0px 5px" : "0px",
          overflow:
            overflow === undefined || overflow === 0 ? "scroll" : "hidden",
          height: "100%",
        }}
      >
        <Table id={`table-${id}`} style={tableStyle}>
          {!hideHeaders && (
            <TableHead>
              <TableRow>
                {columns.map((value, index) => {
                  let style = {
                    ...theme.menu.table.header.style,
                    gridColumn: `${index + 1} / ${index + 1}`,
                    gridRow: "1/1",
                    //! position: "relative", TODO: used for NewScroll
                    zIndex: "2",
                  };
                  if (theme.menu.table.header.overrides) {
                    if (theme.menu.table.header.overrides[index + ""])
                      style = {
                        ...style,
                        ...theme.menu.table.header.overrides[index + ""],
                      };
                    else if (theme.menu.table.header.overrides[value.field])
                      style = {
                        ...style,
                        ...theme.menu.table.header.overrides[value.field],
                      };
                  }
                  //Font overrides
                  if (
                    fontOverrides?.headerFont &&
                    fontOverrides.headerFont !== "Default"
                  ) {
                    style.fontFamily = fontOverrides.headerFont;
                  }
                  if (fontOverrides?.headerColor) {
                    style.color = fontOverrides.headerColor;
                  }
                  if (
                    fontOverrides &&
                    fontOverrides.headerSize &&
                    fontOverrides.headerSize !== "Default"
                  ) {
                    style.fontSize = fontOverrides.headerSize;
                  }
                  if (
                    fontOverrides &&
                    fontOverrides.headerPadding &&
                    fontOverrides.headerPadding !== "Default"
                  ) {
                    style.padding = `${fontOverrides.headerPadding}px`;
                  }
                  const isParseableColumn = parseableColumns.includes(
                    value.header
                  );

                  if (headerAlignment && headerAlignment !== "default") {
                    style.textAlign = headerAlignment;
                  }

                  if (headerTransform) {
                    style.textTransform = headerTransform;
                  }

                  let columnLabel =
                    isParseableColumn && config.increments === "Gram"
                      ? convertColumnHeaderIncrements(value.header)
                      : value.header;

                  // check column config if any overrides are present
                  const colConfig = columnConfig.find(
                    (col) => col.id === value.field
                  );
                  if (colConfig) {
                    if (colConfig.label && colConfig.label !== "") {
                      columnLabel = colConfig.label;
                    }
                    if (
                      colConfig.backgroundColor &&
                      colConfig.backgroundColor !== ""
                    ) {
                      style.backgroundColor = colConfig.backgroundColor;
                    }
                    if (colConfig.fontColor && colConfig.fontColor !== "") {
                      style.color = colConfig.fontColor;
                    }
                    if (colConfig.colWidth && colConfig.colWidth !== "") {
                      style.width = colConfig.colWidth;
                    }
                  }

                  return (
                    <TableCell id="th" key={`column-${index}`} style={style}>
                      {columnLabel}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          )}

          <TableBody className={`tbody-scroll-${id}`}>
            {inventory.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  style={{
                    ...theme.menu.table.data.style,
                    textAlign: "center",
                    padding: "2em",
                    fontSize:
                      fontOverrides?.dataSize ||
                      theme.menu.table.data.style.fontSize,
                    fontFamily:
                      fontOverrides?.dataFont ||
                      theme.menu.table.data.style.fontFamily,
                    color:
                      fontOverrides?.dataColor ||
                      theme.menu.table.data.style.color,
                    borderBottom: "none",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "0.5em",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1.2em",
                        fontWeight: "bold",
                      }}
                    >
                      Currently Out of Stock
                    </span>
                    <span
                      style={{
                        fontSize: "0.9em",
                        opacity: 0.8,
                      }}
                    >
                      Please check back soon for updates
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              inventory.map((row, rowIndex) => {
                let addStyle = {};

                // Handle row background colors based on colorBy property
                if (colorBy && colors) {
                  if (row[colorBy]) {
                    const color = colors.split(",").find((color) => {
                      return (row[colorBy] || "")
                        .toString()
                        .toLowerCase()
                        .includes(
                          (color.split("|")[0] || "").toString().toLowerCase()
                        );
                    });

                    if (color) {
                      addStyle = { backgroundColor: `#${color.split("|")[1]}` };
                    }
                  }
                }

                return (
                  <TableRow key={`${rowIndex}-${row.sku}`} style={addStyle}>
                    {columns.map((col, colIndex) => {
                      let value;
                      let dataPrefix = null;
                      let dataSuffix = null;

                      let thisColumnConfig = columnConfig.find(
                        (column) => column.id === col.field
                      );
                      let configDecimals =
                        thisColumnConfig?.decimals &&
                        !isNaN(thisColumnConfig.decimals)
                          ? parseInt(thisColumnConfig.decimals)
                          : null;

                      if (
                        configDecimals === null ||
                        configDecimals === undefined
                      ) {
                        configDecimals = 2;
                      }

                      let columnBgColor = thisColumnConfig?.dataBackgroundColor
                        ? thisColumnConfig.dataBackgroundColor
                        : null;
                      let columnFontColor = thisColumnConfig?.dataFontColor
                        ? thisColumnConfig.dataFontColor
                        : null;

                      // Handle different types of values
                      if (col.field === "custom") {
                        value = JSON.parse(row.PricesJSON).PriceTiers[0].Name;
                      } else if (
                        col.field === "Image" &&
                        row.PictureUrl !== null
                      ) {
                        value = (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={row.PictureUrl}
                              alt="product"
                              style={{ height: "70px", width: "auto" }}
                            />
                          </div>
                        );
                      } else {
                        value = row[col.field]
                          ? typeof row[col.field] !== "number"
                            ? row[col.field]
                            : row[col.field].toFixed(configDecimals)
                          : "";
                      }

                      let style = {
                        ...theme.menu.table.data.style,
                        gridColumn: `${colIndex + 1}/${colIndex + 1}`,
                        gridRow: `${rowIndex + 2}/${rowIndex + 2}`,
                      };

                      // Apply theme overrides
                      if (theme.menu.table.data.overrides) {
                        if (theme.menu.table.data.overrides[colIndex + ""]) {
                          style = {
                            ...style,
                            ...theme.menu.table.data.overrides[colIndex + ""],
                          };
                        } else if (
                          theme.menu.table.data.overrides.last &&
                          colIndex === columns.length - 1
                        ) {
                          style = {
                            ...style,
                            ...theme.menu.table.data.overrides.last,
                          };
                        }

                        if (theme.menu.table.data.overrides[col.field]) {
                          style = {
                            ...style,
                            ...theme.menu.table.data.overrides[col.field],
                          };
                        }

                        if (settings) {
                          if (settings.highlight && rowIndex % 2) {
                            style = {
                              ...style,
                              ...theme.specialSettings.highlight,
                            };
                          }

                          if (
                            settings.dominanceIcon &&
                            settings.dominanceIcon !== "none" &&
                            col.field === "Dominance"
                          ) {
                            value = dominanceHelper.getDominance({
                              value,
                              fontOverrides,
                              style,
                              settings,
                            });
                          }
                        }
                      }

                      let display = value;

                      // Handle special formatting for prices
                      if (
                        prices.includes(col.field) &&
                        value !== "" &&
                        taxData
                      ) {
                        const jgStoresRec = [6049, 6050, 6051];

                        if (jgStoresRec.includes(row.StoreId)) {
                          let recVal = parseFloat(value).toFixed(2);
                          let medVal = parseFloat(
                            row[pricesToMed[col.field]]
                          ).toFixed(2);

                          if (
                            taxData?.applyTaxToScreens &&
                            taxData.taxRate > 0
                          ) {
                            recVal = (
                              parseFloat(recVal) *
                              (taxData.taxRate / 100 + 1)
                            ).toFixed(2);
                            medVal = (
                              parseFloat(medVal) *
                              (taxData.taxRate / 100 + 1)
                            ).toFixed(2);
                          }

                          if (
                            taxData?.applyRoundingToScreens &&
                            taxData?.roundingValue !== null
                          ) {
                            if (taxData.roundingValue === "Tenths") {
                              recVal = (
                                Math.round(
                                  (parseFloat(recVal) + Number.EPSILON) * 10
                                ) / 10
                              ).toFixed(2);
                              medVal = (
                                Math.round(
                                  (parseFloat(medVal) + Number.EPSILON) * 10
                                ) / 10
                              ).toFixed(2);
                            } else if (taxData.roundingValue === "Whole") {
                              recVal = (
                                Math.round(
                                  (parseFloat(recVal) + Number.EPSILON) * 1
                                ) / 1
                              ).toFixed(0);
                              medVal = (
                                Math.round(
                                  (parseFloat(medVal) + Number.EPSILON) * 1
                                ) / 1
                              ).toFixed(0);
                            }
                          }

                          if (recVal === medVal) {
                            display = `M/R:$${recVal}`;
                          } else if (isNaN(medVal)) {
                            display = `R:$${recVal}`;
                          } else {
                            display = `M:$${medVal} | R:$${recVal}`;
                          }
                        } else {
                          let displayVal =
                            parseFloat(value).toFixed(configDecimals);

                          if (
                            taxData?.applyTaxToScreens &&
                            taxData.taxRate > 0
                          ) {
                            displayVal = (
                              parseFloat(displayVal) *
                              (taxData.taxRate / 100 + 1)
                            ).toFixed(configDecimals);
                          }

                          if (
                            taxData?.applyRoundingToScreens &&
                            taxData?.roundingValue !== null &&
                            (configDecimals === null ||
                              configDecimals === undefined)
                          ) {
                            if (taxData.roundingValue === "Tenths") {
                              displayVal = (
                                Math.round(
                                  (parseFloat(displayVal) + Number.EPSILON) * 10
                                ) / 10
                              ).toFixed(2);
                            } else if (taxData.roundingValue === "Whole") {
                              displayVal = (
                                Math.round(
                                  (parseFloat(displayVal) + Number.EPSILON) * 1
                                ) / 1
                              ).toFixed(0);
                            }
                          }

                          if (
                            settings.hideNoCents === "hideNoCents" &&
                            displayVal.includes(".00")
                          ) {
                            displayVal = displayVal.replace(".00", "");
                          }

                          display = `${displayVal}`;
                          dataPrefix = (
                            <span
                              style={{
                                fontSize: "65%",
                                top: "-.5em",
                                left: "-.1em",
                                position: "relative",
                              }}
                            >
                              &#36;
                            </span>
                          );
                        }
                      }
                      // Handle weight display
                      else if (col.field === "Weight" && value !== "") {
                        display = `${parseFloat(value).toString()}`;
                        dataSuffix = (
                          <span
                            style={{
                              fontSize: "85%",
                              position: "relative",
                              left: ".075em",
                            }}
                          >
                            {row.Unit}
                          </span>
                        );
                      }
                      // Handle cannabinoid percentages
                      else if (
                        (col.field === "Cbd" ||
                          col.field === "Thca" ||
                          col.field === "Thc" ||
                          col.field === "Tac" ||
                          col.field === "TotalTerpenes" ||
                          col.field === "TotalCannabinoids") &&
                        value !== ""
                      ) {
                        let cannaUnit = row.CannaUnit;

                        if (row.CannaUnit === "%") {
                          cannaUnit = "";
                          dataSuffix = (
                            <span
                              style={{
                                fontSize: "65%",
                                position: "relative",
                                left: ".1em",
                              }}
                            >
                              %
                            </span>
                          );
                        }

                        if (
                          configDecimals !== null &&
                          configDecimals !== undefined
                        ) {
                          thcDecimal = configDecimals;
                        }

                        if (`${thcDecimal}`.toLowerCase() === "default") {
                          display = `${
                            row.CannaUnit === "mg"
                              ? parseFloat(value).toFixed(0)
                              : parseFloat(value).toFixed(2)
                          }${cannaUnit}`;
                        } else {
                          display = `${
                            row.CannaUnit === "mg"
                              ? parseFloat(value).toFixed(0)
                              : parseFloat(value).toFixed(thcDecimal)
                          }${cannaUnit}`;
                        }
                      }
                      // Handle special product name cases
                      else if (col.field === "ProductName") {
                        if (row.StoreId === 6128) {
                          display = value.replace(" | Cure Oahu", " ");
                        }
                      }
                      // Handle quantity display
                      else if (col.field === "Quantity" && value !== "") {
                        display = `${Math.floor(value)}`;
                      }

                      // Apply font overrides
                      if (
                        fontOverrides?.dataFont &&
                        fontOverrides.dataFont !== "Default"
                      ) {
                        style.fontFamily = fontOverrides.dataFont;
                      }
                      if (fontOverrides?.dataColor) {
                        style.color = fontOverrides.dataColor;
                      }
                      if (
                        fontOverrides?.dataSize &&
                        fontOverrides.dataSize !== "Default" &&
                        col.field !== "Image"
                      ) {
                        style.fontSize = fontOverrides.dataSize;
                      }
                      if (
                        fontOverrides?.dataPadding &&
                        fontOverrides.dataPadding !== "Default" &&
                        col.field !== "Image"
                      ) {
                        style.padding = `${fontOverrides.dataPadding}px`;
                      }

                      // Apply text transforms and column-specific styles
                      if (dataTransform) {
                        style.textTransform = dataTransform;
                      }
                      if (columnBgColor) {
                        style.backgroundColor = columnBgColor;
                      }
                      if (columnFontColor) {
                        style.color = columnFontColor;
                      }

                      return (
                        <TableCell
                          id="td"
                          className={`td-scroll-${id}`}
                          key={`element-${rowIndex}-${colIndex}`}
                          style={style}
                        >
                          {dataPrefix}
                          {display}
                          {dataSuffix}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const tables = (overflow, tableConfig) => {
    const responsive = {
      1: {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        },
      },
      2: {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        },
      },
      3: {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        },
      },
    };

    if (overflow === 0 || overflow === undefined) {
      return tableDisplay(data, null, tableConfig);
    } else {
      const dataDivision = Math.ceil(data.length / overflowRows);
      const pages = [];
      for (let i = 1; i <= dataDivision; i++) {
        pages.push(i);
      }

      return (
        <Carousel
          responsive={responsive[overflow]}
          infinite={true}
          autoPlay={dataDivision > overflow}
          autoPlaySpeed={qsPageSpeed}
          arrows={false}
          itemClass="carousel-item-padding"
          sliderClass="carousel-height"
        >
          {pages.map((pageNum) => {
            return tableDisplay(
              data.slice(overflowRows * (pageNum - 1), overflowRows * pageNum),
              true,
              tableConfig
            );
          })}
        </Carousel>
      );
    }
  };

  return (
    <div style={theme.main} id={props.id}>
      <link
        href="https://fonts.googleapis.com/css?family=Open+Sans:400,700,300"
        rel="stylesheet"
        type="text/css"
      ></link>
      <link
        href="https://fonts.googleapis.com/css?family=Bevan&display=swap"
        rel="stylesheet"
      ></link>
      <link
        href="https://fonts.googleapis.com/css?family=Averia+Serif+Libre:700,700i&display=swap"
        rel="stylesheet"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap"
        rel="stylesheet"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
        rel="stylesheet"
      ></link>
      <div
        id={`title-wrapper-${id}`}
        style={{
          ...theme.menu.header.wrapper,
          minHeight: title.length === 0 ? "80px" : "none",
        }}
      >
        <div
          id={`title-preheader-${id}`}
          style={theme.menu.header.preHeader}
        ></div>
        <div id={`title-${id}`} style={titleStyle}>
          {title}
        </div>
      </div>
      {tables(overflow, tableConfig)}
    </div>
  );
};

export default Menu;
