import React, { useState, useEffect } from "react";
import { Dialog, DialogActions } from "@mui/material";
import LayoutPreview from "./DynamicLayoutPreview";

const LayoutEditorModal = ({ open, onClose, layout, onSave }) => {
  const [layoutJson, setLayoutJson] = useState("");
  const [previewLayout, setPreviewLayout] = useState(layout);
  const [jsonError, setJsonError] = useState(null);

  const styles = {
    container: {
      padding: "24px",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "16px",
    },
    contentContainer: {
      display: "flex",
      gap: "24px",
    },
    section: {
      flex: 1,
    },
    sectionTitle: {
      marginBottom: "8px",
      fontWeight: "600",
    },
    textarea: {
      width: "100%",
      height: "500px",
      fontFamily: "monospace",
      fontSize: "14px",
      padding: "8px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    },
    textareaError: {
      border: "1px solid #ff0000",
    },
    errorText: {
      color: "#ff0000",
      fontSize: "14px",
      marginTop: "4px",
    },
    previewContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f5f5f5",
      borderRadius: "4px",
      padding: "16px",
    },
    actions: {
      padding: "16px",
      borderTop: "1px solid #e0e0e0",
    },
    button: {
      padding: "8px 16px",
      marginLeft: "8px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    cancelButton: {
      backgroundColor: "#f5f5f5",
      color: "#333",
    },
    saveButton: {
      backgroundColor: "#1976d2",
      color: "white",
    },
    saveButtonDisabled: {
      opacity: 0.5,
      cursor: "not-allowed",
    },
  };

  useEffect(() => {
    setLayoutJson(JSON.stringify(layout, null, 2));
    setPreviewLayout(layout);
  }, [layout]);

  const handleJsonChange = (event) => {
    const newJsonString = event.target.value;
    setLayoutJson(newJsonString);

    try {
      const parsed = JSON.parse(newJsonString);
      setPreviewLayout(parsed);
      setJsonError(null);
    } catch (error) {
      setJsonError("Invalid JSON format");
    }
  };

  const handleSave = () => {
    if (!jsonError) {
      onSave(previewLayout);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <div style={styles.container}>
        <h2 style={styles.title}>Edit Layout</h2>
        <div style={styles.contentContainer}>
          <div style={styles.section}>
            <div style={styles.sectionTitle}>Layout Configuration</div>
            <textarea
              style={{
                ...styles.textarea,
                ...(jsonError ? styles.textareaError : {}),
              }}
              value={layoutJson}
              onChange={handleJsonChange}
            />
            {jsonError && <div style={styles.errorText}>{jsonError}</div>}
          </div>
          <div style={styles.section}>
            <div style={styles.sectionTitle}>Preview</div>
            <div style={styles.previewContainer}>
              <LayoutPreview layout={previewLayout} size="large" />
            </div>
          </div>
        </div>
      </div>
      <DialogActions style={styles.actions}>
        <button
          style={{ ...styles.button, ...styles.cancelButton }}
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          style={{
            ...styles.button,
            ...styles.saveButton,
            ...(jsonError ? styles.saveButtonDisabled : {}),
          }}
          onClick={handleSave}
          disabled={!!jsonError}
        >
          Save Layout
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default LayoutEditorModal;
