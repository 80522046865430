import React from "react";
import styled from "@emotion/styled";

// Base container for the layout
const LayoutContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => props.height}px;
  overflow: hidden;
  background: ${(props) => props.background || "transparent"};
`;

// Zone component with margin support
const Zone = styled.div`
  position: ${(props) => props.position || "absolute"};
  width: calc(
    ${(props) => props.width} - ${(props) => props.horizontalMargin}px
  );
  height: calc(
    ${(props) => props.height} - ${(props) => props.verticalMargin}px
  );
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  z-index: ${(props) => props.zIndex || "auto"};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding || "20px"};
  overflow: ${(props) => props.overflow || "auto"};
  box-sizing: border-box;

  // Apply any custom styles passed through props
  ${(props) => props.customStyles}
`;

const DynamicLayout = ({
  layout,
  areas,
  containerHeight,
  findOrCreateArea,
  AreaComponent,
  theme,
  editMode,
  ...props
}) => {
  console.log("DynamicLayout", layout, areas, containerHeight, theme, editMode);

  return (
    <LayoutContainer
      height={containerHeight}
      background={layout.backgroundColor}
      style={layout.containerStyle}
    >
      {layout.zones.map((zone) => {
        const area = findOrCreateArea(areas, zone.id);

        // Calculate margins
        const margins = {
          top: zone.marginTop || zone.margin || 0,
          right: zone.marginRight || zone.margin || 0,
          bottom: zone.marginBottom || zone.margin || 0,
          left: zone.marginLeft || zone.margin || 0,
        };

        // Calculate total margins for width/height calculations
        const horizontalMargin = margins.left + margins.right;
        const verticalMargin = margins.top + margins.bottom;

        const zoneProps = {
          width: zone.width,
          height: zone.height,
          position: zone.position || "absolute",
          top: `calc(${zone.top} + ${margins.top}px)`,
          left: `calc(${zone.left} + ${margins.left}px)`,
          right: zone.right,
          bottom: zone.bottom,
          zIndex: zone.zIndex || 10,
          margin: `${margins.top}px ${margins.right}px ${margins.bottom}px ${margins.left}px`,
          padding: zone.padding || "20px",
          overflow: zone.overflow || "auto",
          horizontalMargin,
          verticalMargin,
          customStyles: zone.customStyles,
        };

        return (
          <Zone
            key={zone.id}
            {...zoneProps}
            className={`zone-${zone.id}`}
            data-zone-id={zone.id}
          >
            <AreaComponent
              area={area}
              theme={theme}
              editMode={editMode}
              {...props}
            />
            {editMode && (
              <div className="zone-overlay">
                {/* Add editing UI elements here if needed */}
              </div>
            )}
          </Zone>
        );
      })}
    </LayoutContainer>
  );
};

export default DynamicLayout;
