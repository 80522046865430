import React, { useState, useEffect, useRef } from "react";

// Custom hook to manage debug logs with automatic rotation
const useDebugLogs = (maxLogs = 50) => {
  const [logs, setLogs] = useState([]);

  const addLog = (message, type = "info") => {
    const timestamp = new Date().toLocaleTimeString();
    setLogs((prevLogs) => {
      const newLogs = [{ message, timestamp, type }, ...prevLogs].slice(
        0,
        maxLogs
      );
      return newLogs;
    });
  };

  return { logs, addLog };
};

// Debug overlay component to display logs
const DebugOverlay = ({ logs, isVisible, onClear }) => {
  if (!isVisible) return null;

  return (
    <div
      style={{
        position: "absolute",
        left: -1200,
        top: 0,
        width: "1200px",
        height: "100vh",
        background: "rgba(0, 0, 0, 0.9)",
        color: "#fff",
        overflowY: "auto",
        padding: "10px",
        fontSize: "14px",
        zIndex: 9999,
        fontFamily: "monospace",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: 0,
          background: "rgba(0, 0, 0, 0.95)",
          padding: "5px 0",
        }}
      >
        <button
          onClick={onClear}
          style={{
            background: "#444",
            border: "none",
            color: "white",
            padding: "5px 10px",
            borderRadius: "3px",
            cursor: "pointer",
          }}
        >
          Clear Logs
        </button>
      </div>
      {logs.map((log, index) => (
        <div
          key={index}
          style={{
            marginBottom: "5px",
            color:
              log.type === "error"
                ? "#fff"
                : log.type === "warning"
                ? "#fff"
                : "#fff",
            borderLeft: `3px solid ${
              log.type === "error"
                ? "#fff"
                : log.type === "warning"
                ? "#fff"
                : "#fff"
            }`,
            paddingLeft: "5px",
          }}
        >
          [{log.timestamp}] {log.message}
        </div>
      ))}
    </div>
  );
};


const ScheduledMediaZone = ({
  mediaItems,
  defaultFitStyle = "cover",
  customStyles = {},
  fallbackColor = "#000000",
  transitionDuration = 1000,
  children,
  debugMode = true,
}) => {
  // State management
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadedUrls, setLoadedUrls] = useState(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [lastTransitionTime, setLastTransitionTime] = useState(Date.now());
  const [playbackErrors, setPlaybackErrors] = useState(0);
  const [mediaState, setMediaState] = useState({
    currentTime: 0,
    duration: 0,
    readyState: 0,
  });

  // Debug logging system
  const { logs, addLog } = useDebugLogs(100);

  // Refs for media elements and timers
  const currentMediaRef = useRef(null);
  const nextMediaRef = useRef(null);
  const transitionTimer = useRef(null);
  const durationTimer = useRef(null);
  const watchdogTimer = useRef(null);
  const errorRecoveryAttempts = useRef(0);

  // Get current and next items
  const currentItem = mediaItems[currentIndex];
  const nextItem = mediaItems[(currentIndex + 1) % mediaItems.length];

  // Watchdog timer implementation
  const resetWatchdog = () => {
    clearTimeout(watchdogTimer.current);
    watchdogTimer.current = setTimeout(() => {
      const timeSinceLastTransition = Date.now() - lastTransitionTime;
      if (timeSinceLastTransition > 30000) {
        addLog(
          "⚠️ Watchdog detected possible stuck state - forcing next item",
          "warning"
        );
        forceNextItem();
      }
    }, 35000);
  };

  // Force transition to next item
  const forceNextItem = () => {
    addLog("🔄 Forcing transition to next item", "warning");
    setPlaybackErrors((prev) => prev + 1);
    errorRecoveryAttempts.current += 1;

    // If we've had too many errors, try reloading all media
    if (errorRecoveryAttempts.current > 5) {
      addLog("🔄 Too many errors - attempting full media reload", "warning");
      reloadAllMedia();
      errorRecoveryAttempts.current = 0;
    } else {
      transitionToNext();
    }
  };

  // Media preloading with retry mechanism
  const preloadItem = async (item, retryCount = 3) => {
    addLog(`📥 Starting preload for ${item.type}: ${item.url}`);

    for (let attempt = 1; attempt <= retryCount; attempt++) {
      try {
        await new Promise((resolve, reject) => {
          if (item.type === "video") {
            const videoElement = document.createElement("video");

            const timeoutId = setTimeout(() => {
              reject(new Error("Video preload timeout"));
            }, 10000);

            videoElement.onloadeddata = () => {
              clearTimeout(timeoutId);
              resolve();
            };

            videoElement.onerror = (error) => {
              clearTimeout(timeoutId);
              reject(new Error(`Video load error: ${error.message}`));
            };

            videoElement.preload = "auto";
            videoElement.src = item.url;
          } else {
            const img = new Image();

            const timeoutId = setTimeout(() => {
              reject(new Error("Image preload timeout"));
            }, 10000);

            img.onload = () => {
              clearTimeout(timeoutId);
              resolve();
            };

            img.onerror = (error) => {
              clearTimeout(timeoutId);
              reject(new Error(`Image load error: ${error.message}`));
            };

            img.src = item.url;
          }
        });

        setLoadedUrls((prev) => new Set([...prev, item.url]));
        addLog(`✅ Successfully preloaded ${item.type}: ${item.url}`);
        return;
      } catch (error) {
        addLog(
          `❌ Attempt ${attempt} failed to preload ${item.url}: ${error.message}`,
          "error"
        );
        if (attempt === retryCount) {
          throw error;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000 * attempt));
      }
    }
  };

  // Handle transition to next media item
  const transitionToNext = async () => {
    addLog(
      `🔄 Starting transition from index ${currentIndex} to ${
        (currentIndex + 1) % mediaItems.length
      }`
    );

    clearTimeout(transitionTimer.current);
    clearTimeout(durationTimer.current);

    // Handle current video
    if (currentMediaRef.current && currentItem.type === "video") {
      try {
        currentMediaRef.current.pause();
        // Clear video source to help with memory management
        currentMediaRef.current.removeAttribute("src");
        currentMediaRef.current.load();
      } catch (error) {
        addLog(`❌ Error cleaning up current video: ${error.message}`, "error");
      }
    }

    // Prepare next video
    if (nextMediaRef.current && nextItem.type === "video") {
      nextMediaRef.current.currentTime = 0;
      try {
        const playPromise = nextMediaRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch((err) => {
            addLog(`❌ Video playback failed: ${err.message}`, "error");
            setPlaybackErrors((prev) => prev + 1);
          });
        }
      } catch (err) {
        addLog(`❌ Video playback failed: ${err.message}`, "error");
        setPlaybackErrors((prev) => prev + 1);
      }
    }

    // Schedule the transition
    transitionTimer.current = setTimeout(() => {
      setCurrentIndex((prev) => (prev + 1) % mediaItems.length);
      setLastTransitionTime(Date.now());
      resetWatchdog();
      errorRecoveryAttempts.current = 0; // Reset error count on successful transition
    }, transitionDuration);
  };

  // Schedule next transition based on media type
  const scheduleNextTransition = () => {
    clearTimeout(durationTimer.current);

    if (currentItem.type === "video" && currentMediaRef.current) {
      addLog(`⏱️ Setting up video end transition for: ${currentItem.url}`);
      currentMediaRef.current.onended = () => {
        addLog(`📽️ Video ended naturally: ${currentItem.url}`);
        transitionToNext();
      };
    } else if (currentItem.type === "image") {
      const durationMs =
        (currentItem.duration.minutes * 60 + currentItem.duration.seconds) *
        1000;
      addLog(
        `⏱️ Scheduling image transition in ${durationMs}ms for: ${currentItem.url}`
      );
      durationTimer.current = setTimeout(transitionToNext, durationMs);
    }
  };

  // Reload all media
  const reloadAllMedia = async () => {
    addLog("🔄 Reloading all media", "warning");
    setIsLoading(true);
    setLoadedUrls(new Set());

    try {
      await Promise.all(mediaItems.map(preloadItem));
      addLog("✅ Successfully reloaded all media");
    } catch (error) {
      addLog(`❌ Error reloading media: ${error.message}`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  // Effect for initial media loading
  useEffect(() => {
    const loadAllMedia = async () => {
      addLog(`🚀 Starting to load ${mediaItems.length} media items`);
      setIsLoading(true);

      try {
        // First, preload just the first item to show it immediately
        const firstItem = mediaItems[0];
        await preloadItem(firstItem);
        setLoadedUrls(new Set([firstItem.url]));
        setIsLoading(false); // Unblock rendering for first item
        addLog("✅ First item loaded and ready to display");

        // Then load the rest of the items in the background
        const remainingItems = mediaItems.slice(1);
        await Promise.all(remainingItems.map(preloadItem));
        setLoadedUrls(
          (prev) =>
            new Set([...prev, ...remainingItems.map((item) => item.url)])
        );
        addLog("✅ Successfully loaded all media items");

        // Schedule first transition only after all items are loaded
        if (firstItem.type === "image") {
          const durationMs =
            (firstItem.duration.minutes * 60 + firstItem.duration.seconds) *
            1000;
          addLog(`⏱️ Scheduling first transition in ${durationMs}ms`);
          durationTimer.current = setTimeout(transitionToNext, durationMs);
        }
      } catch (error) {
        addLog(`❌ Error loading media items: ${error.message}`, "error");
        setIsLoading(false);
      }
    };

    loadAllMedia();
    resetWatchdog();

    return () => {
      clearTimeout(watchdogTimer.current);
      clearTimeout(transitionTimer.current);
      clearTimeout(durationTimer.current);
    };
  }, [mediaItems]);

  // Effect for transition scheduling
  useEffect(() => {
    if (!isLoading) {
      scheduleNextTransition();
    }

    return () => {
      clearTimeout(durationTimer.current);
      if (currentMediaRef.current) {
        currentMediaRef.current.onended = null;
      }
    };
  }, [currentIndex, isLoading]);

  // Media rendering function
  const renderMedia = (item, isCurrent) => {
    if (!item || !loadedUrls.has(item.url)) {
      return null;
    }

    const commonStyles = {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      opacity: isCurrent ? 1 : 0,
      transition: `opacity ${transitionDuration}ms ease-in-out`,
      objectFit: item.fitStyle || defaultFitStyle,
      ...customStyles?.media,
    };

    if (item.type === "video") {
      return (
        <video
          ref={isCurrent ? currentMediaRef : nextMediaRef}
          key={item.url}
          src={item.url}
          style={commonStyles}
          muted
          playsInline
          autoPlay={isCurrent}
          onError={(e) => {
            addLog(
              `❌ Video playback error: ${
                e.target.error?.message || "Unknown error"
              }`,
              "error"
            );
            if (isCurrent) forceNextItem();
          }}
          onPlay={() => addLog(`▶️ Video started playing: ${item.url}`)}
          onEnded={() => addLog(`⏹️ Video ended: ${item.url}`)}
          onTimeUpdate={(e) => {
            if (isCurrent) {
              setMediaState({
                currentTime: e.target.currentTime,
                duration: e.target.duration,
                readyState: e.target.readyState,
              });
            }
          }}
        />
      );
    }

    return (
      <img
        key={item.url}
        src={item.url}
        alt=""
        style={commonStyles}
        onError={(e) => {
          addLog(
            `❌ Image display error: ${
              e.target.error?.message || "Unknown error"
            }`,
            "error"
          );
          if (isCurrent) forceNextItem();
        }}
        onLoad={() => addLog(`✅ Image displayed: ${item.url}`)}
      />
    );
  };

  // Clear logs handler
  const handleClearLogs = () => {
    addLog("🧹 Logs cleared");
  };

  // Loading state render
  if (isLoading) {
    return (
      <div
        style={{
          backgroundColor: fallbackColor,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#ffffff",
          ...customStyles?.container,
        }}
      >
        Loading media... ({loadedUrls.size}/{mediaItems.length})
      </div>
    );
  }

  // Main render
  return (
    <>
      <DebugOverlay
        logs={logs}
        isVisible={debugMode}
        onClear={handleClearLogs}
      />
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          backgroundColor: fallbackColor,
          overflow: "hidden",
          ...customStyles?.container,
        }}
      >
        {renderMedia(currentItem, true)}
        {renderMedia(nextItem, false)}
        <div style={{ position: "relative", zIndex: 1 }}>{children}</div>
      </div>
    </>
  );
};

export default ScheduledMediaZone;
