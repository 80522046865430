import React from "react";
import ErrorBoundary from "./ErrorBoundary";

import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import AppRoute from "./App.Route";
import AppStore from "./App.Store";
import { configure } from "axios-hooks";
import LRU from "lru-cache";
import Axios from "axios";
import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const cache = new LRU({ max: 10 });

configure({ axios, cache });

function App() {
  return (
    <ErrorBoundary
      onError={(error, errorInfo) => {
        // Add any custom error handling here
        console.error("App Error:", error);
      }}
    >
      <ApolloProvider client={client}>
        <ToastProvider>
          <Provider store={AppStore}>
            <AppRoute />
          </Provider>
        </ToastProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export default App;
