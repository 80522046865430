export const defaultDynamicLayout = {
  id: "dynamic",
  type: "dynamic",
  backgroundColor: "#ffffff",
  containerStyle: {
    display: "block",
  },
  zones: [
    // Left column
    {
      id: "a",
      width: "33.333%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: 10,
      position: "absolute",
      overflow: "auto",
    },
    // Middle column
    {
      id: "b",
      width: "33.333%",
      height: "100%",
      top: "0",
      left: "33.333%",
      zIndex: 10,
      position: "absolute",
      overflow: "auto",
    },
    // Right column
    {
      id: "c",
      width: "33.334%", // Slightly larger to account for potential rounding issues
      height: "100%",
      top: "0",
      left: "66.666%",
      zIndex: 10,
      position: "absolute",
      overflow: "auto",
    },
  ],
};
